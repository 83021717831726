<template>
  <div id="app">
    <div class="inputBox">
      <h3 style="font-weight: 500;">設定新密碼</h3>
      <mu-text-field type="password" v-model="newPass" :error-text="isPassword" placeholder="請輸入 6 位數以上英數密碼" style="width: 90%;"></mu-text-field><br/>
      <mu-text-field type="password" v-model="dbPass" :error-text="samePassword" placeholder="請再次輸入密碼" style="width: 90%;"></mu-text-field>
      <mu-button color="primary" style="width: 90%; margin-bottom: 20px; margin-top: 20px;" @click="checkPass">送出</mu-button>
    </div>
    <mu-dialog width="450" :open="dialogMsg">
      <div style="margin-bottom: 20px;">{{ result }}</div>
      <mu-button v-if="result === '已更新用戶密碼'" slot="actions" color="primary" @click="backToLogin">返回登入頁</mu-button>
    </mu-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, createNamespacedHelpers } from 'vuex';

const emc = createNamespacedHelpers('emc');


export default {
  name: 'HelloWorld',
  data() {
    return {
      uid: this.$route.params.uid,
      newPass: '',
      dbPass: '',
      isPassword: '',
      samePassword: '',
      dialogMsg: false,
      result: ''
    }
  },
  methods: {
    ...emc.mapActions({
      changePass: 'changePass'
    }),
    backToLogin() {
      this.$router.push('/');
    },
    checkPass() {
      if (this.newPass.length >= 6) {
        if (this.dbPass === this.newPass) {
          this.samePassword = '';
          this.isPassword = '';
          this.dialogMsg = true;
          this.result = '設定中...';
          this.changePass({ uid: this.uid, newPass: this.newPass })
            .then((resp) => {
              this.result = resp.data.Message
            })
        }
        else {
          this.samePassword = '密碼不符合！'
        }
      }
      else {
        this.isPassword = '密碼長度不足！'
      }
    }
  },
}
</script>

<style lang="scss" scoped>
  #app {
    background: 100% linear-gradient(to left, rgb(0,128,129),rgb(0,49,82));
    padding: 20px;
    min-height: 800px;
    height: 100vh;
  }
  .inputBox {
    width: 90%;
    max-width: 450px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 8px;
    top: 15vh;
    background-color: #fff;
    display: inline-block;
    padding: 10px 10px;
  }
</style>